import Heading from "Components/Heading";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { BiSearch, BiExport, BiLike } from "react-icons/bi";
import StationCodesAPIs from "../../APIs/station-code";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "Components/Footer";
import TicketAPIs from "../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import { formatDate } from "Helper/Converters";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";
import { exportFileToExcel } from "Utils/utils";

const DATE_TYPE_OPTIONS = [
  { value: "", label: "Select" },
  { value: "completeDate", label: "Completed Date" },
  { value: "enteredDate", label: "Entered Date" },
  { value: "dueDate", label: "Due Date" },
  { value: "assignDate", label: "Assigned Date" },
  { value: "cancelDate", label: "Cancelled Date" },
];

const INITIAL_VALUES = {
  stationCodeIds: [],
  dateType: DATE_TYPE_OPTIONS[2],
  fromDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
  toDate: new Date(),
  nofDays: 5,
};

const ValidationSchema = Yup.object().shape({
  dateType: Yup.object()
    .shape({
      value: Yup.string().required("Date Type is required"),
    })
    .required("Date Type is required"),
  fromDate: Yup.date().required("Start Date is required"),
  toDate: Yup.date().required("End Date is required"),
  nofDays: Yup.number().min(1, "Business Days must be atleast 1"),
});

const NonComplaintReport = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [stationCodes, setStationCodes] = useState([]);
  const [isCompliant, setIsCompliant] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [searchedMasterData, setSearchedMasterData] = useState([]);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const options = [
    { value: "0", label: "Open this select menu" },
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const getAllStationCodes = async () => {
    const stationCodes = await StationCodesAPIs.getStationCode();
    if (stationCodes.data?.success) {
      setStationCodes(
        stationCodes.data.data
          ?.filter((item) => item.isFilterable)
          .sort((a, b) => a.name.localeCompare(b.name)) || []
      );
    }
  };

  useEffect(() => {
    getAllStationCodes();
  }, []);

  const cleanAddress = (address) => {
    return address
      .split(",")
      .map((part) => part.trim())
      .filter((part) => part.length > 0)
      .join(", ");
  };

  const handleSubmit = async (values) => {
    values = {
      ...values,
      fromDate: values.fromDate
        ? moment(values.fromDate).format("YYYY-MM-DD")
        : null,
      toDate: values.toDate ? moment(values.toDate).format("YYYY-MM-DD") : null,
      dateType: values.dateType.value,
      nofDays: values.nofDays.toString(),
    };
    setLoading(true);
    const search = await TicketAPIs.nonCompliantReport(values);
    if (search.data?.success) {
      let arr =
        search.data.data.map((item) => ({
          ...item,
          digaddress: cleanAddress(item.digaddress),
        })) || [];
      setSearchedMasterData(arr);
      if (!isCompliant) {
        setSearchedData(arr.filter((item) => item.nonCompliantReason != ""));
      } else {
        setSearchedData(arr);
      }
    }
    setLoading(false);
  };

  const columns = [
    {
      accessor: "ticket_no",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticket_no}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticket_no}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticket_no}`}
          rel="noopener noreferrer"
          className={`badge ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticket_no}
        </a>
      ),
    },
    {
      Header: "Address",
      Cell: ({ row }) => <p>{row.original.digaddress}</p>,
    },
    { accessor: "contractorname", Header: "Contractor" },
    {
      Header: "Entered Date",
      Cell: ({ row }) => (
        <p>
          {row.original.entereddate ? formatDate(row.original.entereddate) : ""}
        </p>
      ),
    },
    {
      Header: "Compliance Date",
      Cell: ({ row }) => (
        <p>
          {row.original.originalcompliancedate
            ? formatDate(row.original.originalcompliancedate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Renegotiated Date",
      Cell: ({ row }) => (
        <p>
          {row.original.renegotiateddate
            ? formatDate(row.original.renegotiateddate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Completed Date",
      Cell: ({ row }) => (
        <p>
          {row.original.completeddate
            ? formatDate(row.original.completeddate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Pre Completed Date",
      Cell: ({ row }) => (
        <p>
          {row.original.precompleteddate
            ? formatDate(row.original.precompleteddate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Renegotiated Attempt Date",
      Cell: ({ row }) => (
        <p>
          {row.original.renegotiatedattempteddate
            ? formatDate(row.original.renegotiatedattempteddate)
            : ""}
        </p>
      ),
    },
    {
      Header: "Non Compliant Reason",
      Cell: ({ row }) => <p>{row.original.nonCompliantReason}</p>,
    },
  ];

  const formatDateForExcel = () => {
    let formattedData = [
      [
        {
          v: "Ticket No.",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Address",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Entered Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Compliance Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Renegotiated Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Completed Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Renegotiated Attempt Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Non Compliant Reason",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ],
    ];
    searchedData.forEach((item) => {
      console.log(item);
      formattedData.push([
        { v: item.ticket_no },
        { v: item.digaddress },
        { v: item.contractorname },
        { v: item.entereddate ? formatDate(item.entereddate) : "" },
        {
          v: item.complianceDate
            ? item.hasticketrescheduledremark == 1
              ? formatDate(item.originalcompliancedate)
              : formatDate(item.complianceDate)
            : "",
        },
        { v: item.renegotiateddate ? formatDate(item.renegotiateddate) : "" },
        { v: item.completeddate ? formatDate(item.completeddate) : "" },
        {
          v: item.renegotiatedattempteddate
            ? formatDate(item.renegotiatedattempteddate)
            : "",
        },
        {
          v: item.nonCompliantReason,
        },
      ]);
    });
    return formattedData;
  };

  const exportToExcel = () => {
    if (!(searchedData.length > 0)) {
      toast.error("No data found yet!");
      return;
    }
    const data = formatDateForExcel();
    const fileName = "Non_Compliance_Report";
    exportFileToExcel(data, fileName);
  };

  const renderPercentage = useMemo(() => {
    return Number(
      (searchedMasterData.filter((item) => item.nonCompliantReason === "")
        .length /
        searchedMasterData.length) *
        100
    ).toFixed(2);
  }, [searchedMasterData]);

  useEffect(() => {
    if (searchedMasterData.length > 0) {
      if (isCompliant)
        setSearchedData(JSON.parse(JSON.stringify(searchedMasterData)));
      else
        setSearchedData(
          searchedMasterData.filter((item) => item.nonCompliantReason !== "")
        );
    }
  }, [isCompliant]);

  return (
    <>
      <section>
        <Heading text={"Non Compliance Report"} />

        <Card>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => {
              return (
                <Form className="dashboard-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Station Codes</Form.Label>
                        <div className="checkboxHolder">
                          {stationCodes.map((item, index) => (
                            <Form.Check
                              type="checkbox"
                              label={item.name}
                              name={`stationCodes-${index}`}
                              checked={
                                values.stationCodeIds.findIndex(
                                  (id) => id == item.id
                                ) > -1
                              }
                              onChange={(e) => {
                                if (
                                  values.stationCodeIds.findIndex(
                                    (id) => id == item.id
                                  ) > -1
                                )
                                  setFieldValue(
                                    "stationCodeIds",
                                    values.stationCodeIds.filter(
                                      (id) => id != item.id
                                    )
                                  );
                                else
                                  setFieldValue("stationCodeIds", [
                                    ...values.stationCodeIds,
                                    item.id,
                                  ]);
                              }}
                            />
                          ))}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date Type</Form.Label>
                        <Select
                          name="dateType"
                          options={DATE_TYPE_OPTIONS}
                          styles={customStyles}
                          value={values.dateType}
                          onChange={(val) => setFieldValue("dateType", val)}
                          onBlur={() => setFieldTouched("dateType", true, true)}
                        />
                        {touched.dateType && errors.dateType?.value && (
                          <span className="error-msg">
                            {errors.dateType?.value}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="fromDate"
                            selected={values.fromDate}
                            onSelect={(date) => {
                              setFieldValue("fromDate", date);
                              setFieldValue("toDate", null);
                            }}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() =>
                              setFieldTouched("fromDate", true, true)
                            }
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.fromDate && errors.fromDate && (
                          <span className="error-msg">{errors.fromDate}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date To</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="toDate"
                            minDate={values.fromDate}
                            selected={values.toDate}
                            onSelect={(date) => setFieldValue("toDate", date)}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() => setFieldTouched("toDate", true, true)}
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.toDate && errors.toDate && (
                          <span className="error-msg">{errors.toDate}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Compliance days set to {""}
                          <input
                            type="number"
                            name="nofDays"
                            value={values.nofDays}
                            min={1}
                            onChange={handleChange}
                            onBlur={() =>
                              setFieldTouched("nofDays", true, true)
                            }
                            style={{ width: 30, textAlign: "center" }}
                            className="number-input-without-arrows"
                          />
                          {""} business days after entered data{" "}
                        </Form.Label>
                        <div className="checkboxHolder">
                          <Form.Check
                            type="checkbox"
                            className="include-check"
                            label="Include Complaints"
                            // name="isCompliant"
                            checked={isCompliant}
                            onChange={(e) => setIsCompliant(e.target.checked)}
                            // onBlur={() =>
                            //   setFieldTouched("isCompliant", true, true)
                            // }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col
                      md={12}
                      className="d-flex justify-content-between mb-4"
                    >
                      <Button onClick={exportToExcel}>
                        <BiExport />
                        Export
                      </Button>
                      <Button style={{ minHeight: "44px" }} type="submit">
                        <BiSearch />
                        {loading ? "Loading..." : "Search"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
          <>
            {searchedMasterData[0] && (
              <strong className="mb-4">
                Enstimated Compliance:{" "}
                <span style={{ backgroundColor: "#ec9706" }} className="px-1">
                  {renderPercentage}% Compliant
                </span>
              </strong>
            )}
            <SampleDataTable columns={columns} data={searchedData} />
          </>
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default NonComplaintReport;
